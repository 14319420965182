/*******custom-component css*******/

.inner-page-main {
    height: calc(100svh - 4.5em);
    overflow-y: auto;
}

.legal-page-container {
    height: calc(100svh - 4.5em);
    background: #f2f2f2; 
    padding: 1.5em 1.5em;
    overflow-y: auto;
    overflow-x: hidden;
}