/*******form-css*******/

fieldset { border:none; padding:0; }
legend { padding:0; }
button { margin:0; }

input, select, button, textarea { font-size: 1em; font-family: "MBcorp-web-light", sans-serif; font-weight: 400; box-sizing: border-box; } 

button, .button {background: var(--clrprimary);border: 0 solid #cdcdcd80;color:#fff; line-height: 1.25em !important; padding: 0.5em 2em; font-weight: 500; font-size: 1em; cursor: pointer; min-height: 3.5em; min-width: 10em; border-radius: var(--buttonradius); text-transform: none; text-decoration: none; display: flex; flex-direction: row; flex-wrap: nowrap; align-content: center; justify-content: center; align-items: center; gap: 1em; touch-action: manipulation; white-space: nowrap; }
button.full-w, .button.full-w { width: 100%; }

button:hover, button:focus, button:visited, 
.button:hover, .button:focus, .button:visited  { color:#fff;  }

.button.block-btn { display: block; text-align: center; text-transform: uppercase; border-radius: 0.25em; }
button.beta, .button.beta {background: var(--mbblue-bg);border: 0 solid var(--mbblue-border);}
button.delta, .button.delta {background: var(--bgsecondary);border: 1px solid #dddddddd;}
button.white, .button.white {background: #fff; color: #222222; border: 1px solid #dddddddd;}
button.hallow, .button.hallow { background: transparent; border: 1px solid #696969dd; }
button.icon_transparent, .button.icon_transparent { background: transparent; border: 0 solid transparent; }
button:disabled, .button:disabled { background: #707070; color: #dddddd; } 
button.trans, .button.trans { background: transparent; border: 0 solid transparent; width: fit-content; width: -webkit-fit-content; }
button.beta:disabled, .button.beta:disabled {background: transparent; border: 1px solid #707070; color: #dddddd; opacity: 0.4;}
button.hallow:disabled, .button.hallow:disabled {background: transparent; border: 1px solid #707070; color: #dddddd; opacity: 0.4;}

button .icon, .icon  {width: 1.25em;height: 1.25em;display: block;}
button.icon-btn { padding: 0 0.125em; }

button > span, .button  > span { padding: 0 0;}
button > span.icon, .button > span.icon { padding: 0;}
button > span.icon + span, .button > span.icon + span  { padding: 0 0.5em 0 0.125em;}

.button-region { width: 100%; padding: 0.25em 0; display: flex; gap: 1em; flex-direction: row; flex-wrap: wrap; align-content: center; align-items: center; justify-content: flex-start;}
.button-region.right { justify-content: flex-end;}
.button-region.center { justify-content: center;}
.button-region.space-between { justify-content: space-between;}
.button-region.full-w { width: 100%;}

input[type='date'], 
input[type='text'], 
input[type='password'], 
input[type='email'], 
input[type='datetime-local'], 
input[type='month'], 
input[type='search'], 
input[type='number'], 
input[type='tel'], 
input[type='url'], 
input[type='week'], 
input[type='time'],
textarea {
	padding: 0.5em 0;
	border: 1px solid #979797;
	border-width: 0 0 1px;
	height: 2.5em;
	width: 100%;
	background: transparent;
	color: #fff;
} 

input[type='date'] { max-width: 11.5em;}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../../images/icon_down_arrow.svg);
  background-repeat: no-repeat;
  background-position-x: calc(100% - 0.5em);
  background-position-y: 50%;
  background-size: 1em;
  border-radius: 0;
  padding: 0.25em 2em 0.25em 0.75em;
  height: 2.5em;
  max-width: inherit;
  min-width: 98%; 
}

textarea {
  padding: 0.5em 0;
  border: 1px solid #979797;
  border-width: 0 0 1px;
  height: auto;
  width: 100%;
  font-size: 1em;
  border-radius: 0;
  resize: vertical;
  position: relative;
  z-index: 1;
}

textarea::-webkit-scrollbar { width:0.25em !important; }
textarea::-webkit-scrollbar-track { background: transparent !important; -webkit-box-shadow: inset 0px 0px 0px #00000010; border-radius:0.75em; }  
textarea::-webkit-scrollbar-thumb { width: auto; margin: 0; cursor: pointer; background: #ffffff24 !important; border-radius: 0.75em;  border: 1px solid transparent; }

* :focus-visible { outline: none;}

textarea.event-desc-txt { resize: none; }

.filter-bar button, .filter-bar .button  { box-sizing: border-box;}
.filter-item-divider {width: 1px;height: 3em;display: block;margin: 0 1em;background: #ccc;}

.btn-outline-primary { color: var(--clrprimary); border-color: var(--clrprimary);}
.btn-check:active + .btn-outline-primary, 
.btn-check:checked + .btn-outline-primary, 
.btn-outline-primary.active, 
.btn-outline-primary.dropdown-toggle.show, 
.btn-outline-primary:active { color: #fff; background-color:  #c3002f; border-color:  #c3002f;}

.btn-outline-primary:hover { color: #fff; background-color:  #c3002f; border-color:  #c3002f;}

.btn-check:active + .btn-outline-primary:focus, 
.btn-check:checked + .btn-outline-primary:focus, 
.btn-outline-primary.active:focus, 
.btn-outline-primary.dropdown-toggle.show:focus, 
.btn-outline-primary:active:focus { box-shadow: 0 0 0 0.25rem rgb(195 0 47 / 50%) !important;}

.btn-check:focus+.btn-outline-primary, .btn-outline-primary:focus { box-shadow: 0 0 0 0.25rem rgb(195 0 47 / 50%) !important; }

.form-check-input:checked { background-color: #fff;  border-color: #333;}
.form-check-input:focus { border-color: #c3002f; outline: 0; box-shadow: 0 0 0 0.25rem rgb(195 0 47 / 25%);}

.form-check-input:checked[type=radio] {background-image: url(../../images/radio.svg); background-size: auto; background-position: 50% 50%;}
.form-check-input:checked[type=checkbox] {background-image: url(../../images/checkbox.svg); background-size: auto; background-position: 50% 50%;}
input[type='file'] { padding: 0.125em; border: 1px solid #ccc; }


.form-check.form-switch { display: flex; flex-wrap: wrap; align-items: center; gap: 1em;}
.form-check-input {width: 2em;height: 2em;vertical-align: top;background-color: #fff;background-repeat: no-repeat;background-position: center;background-size: contain;border: 1px solid rgba(0,0,0,.25);cursor: pointer; -webkit-appearance: none;-moz-appearance: none;appearance: none;-webkit-print-color-adjust: exact; print-color-adjust: exact; }

.form-switch .form-check-input[type=checkbox] {border-radius: 2em;}
.form-switch .form-check-input {width: 4em;background-image: url(../../images/ic_switch_default.svg);background-position: left center;border-radius: 2em;transition: background-position .15s ease-in-out;}
.form-switch .form-check-input:checked { background-color:  var(--clrprimary); border-color:  var(--clrprimary); background-position: right center;  background-image: url(../../images/ic_switch_select.svg);}

.form-check-input:disabled { pointer-events: none; filter: none; opacity: 0.5;}

.btn-group, .btn-group-vertical { position: relative; display: inline-flex; vertical-align: middle;}
.btn-group > .btn-group:not(:last-child)> .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) { border-top-right-radius: 0; border-bottom-right-radius: 0; }

.btn-check { position: absolute; clip: rect(0,0,0,0); pointer-events: none;}

.btn { display: inline-block; font-weight: 500; line-height: 1.5; color: #212529; text-align: center; text-decoration: none; vertical-align: middle; cursor: pointer; -webkit-user-select: none; -moz-user-select: none; user-select: none; background-color: transparent; border: 1px solid transparent; padding: 0.5em 0.75em; border-radius: 0; transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;}

.btn-group > .btn-group:not(:first-child), 
.btn-group>.btn:not(:first-child) { margin-left: -1px;}

.btn-group-vertical > .btn-check:checked+.btn, 
.btn-group-vertical > .btn-check:focus+.btn, 
.btn-group-vertical > .btn.active, 
.btn-group-vertical > .btn:active, 
.btn-group-vertical > .btn:focus, 
.btn-group-vertical > .btn:hover, 
.btn-group > .btn-check:checked+.btn, 
.btn-group > .btn-check:focus+.btn, 
.btn-group > .btn.active, 
.btn-group > .btn:active, 
.btn-group > .btn:focus, 
.btn-group > .btn:hover {  z-index: 1;}

.btn-group-vertical > .btn, 
.btn-group > .btn { position: relative; flex: 1 1 auto; }

.form-row {display: flex;flex-direction: row;gap: 1em;width: 100%; padding: 0 0 1.5em; flex-wrap: wrap;align-content: flex-start;justify-content: flex-start;align-items: center;position: relative;z-index: 2;}
.form-group {display: flex;flex-direction: row;gap: 1em;flex-wrap: wrap;align-content: center;justify-content: flex-start;align-items: center;}
.form-item {display: flex;flex-direction: column;gap: 1em;width: fit-content;flex-wrap: nowrap;align-content: center;align-items: flex-start;justify-content: flex-start;position: relative;padding: 1em 0 0;}

.form-row.no-padding-bottom { padding: 1em 0 0; }

.form-item.material { position: relative; padding: 1.5em 0 0;}

.form-item.full-w { width: 100%; }
.form-item.material-underline { color: #fff; padding: 0 0 0; gap: 0; }
.form-item.material-underline label { font-size: 0.875em; font-weight: 700; color: #a8a8a8; }

.form-item.material-underline textarea + .form-note-text { padding: 1em 0 0; }

.btn-group-rd-ck { display: flex; flex-direction: row; flex-wrap: nowrap; gap: 3em; }
.form-check-item { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: flex-start; align-content: center; gap: 0.75em;}
.form-item.material-underline .form-check-item label { font-size: 1.125em; color: #fff; font-weight: 500; margin: 0.25em 0 0; }

.screen-content-header .form-row { padding: 0 0 0; }
/*.screen-content-header .form-row .form-item { padding: 0 0 0; } */

.form-item.material input { padding: 0; }
.form-item.material input ~ label {position: absolute;left: 0;top: 2em; margin: auto; width: 100%; height: fit-content; pointer-events: none;transition: all 0.2s ease;}
.form-item.material input:focus ~ label, .form-item.material input.not-empty ~ label  { top: 0; font-size: 0.875em;bottom: a;transition: all 0.2s ease;}

.form-item.material textarea { padding: 0; border-radius: 0; border-width: 0 0 1px; border-bottom: 1px solid #979797; }
.form-item.material textarea ~ label {position: absolute;left: 0; top: 2.5em; margin: auto; width: 100%; height: fit-content; pointer-events: none;transition: all 0.2s ease;}
.form-item.material textarea:focus ~ label, .form-item.material textarea.content-available ~ label  { top: 0; bottom: auto; font-size: 0.875em; transition: all 0.2s ease;}


.form-item.material input ~ label, 
.form-item.material textarea ~ label { color: #fff; z-index: 1;}

.form-note-text { font-size: 0.75em; width: 100%; }
.form-note-text.right { text-align: right; }


.form-item.non-editable input { border: none; background: none; pointer-events: none;}

.form-item.stable { position: relative; padding: 1.5em 0 0;}
.form-item.stable input ~ label, .form-item.stable textarea ~ label  { position: absolute; left: 0; top: 0; margin: auto; width: 100%; height: fit-content; pointer-events: none; font-size: 0.875em;bottom: a;transition: all 0.2s ease;}

.form-item.ch-w-10, .form-clm-ch-w-10 { width: 10ch;}
.form-item.ch-w-15, .form-clm-ch-w-15 { width: 15ch;}
.form-item.ch-w-20, .form-clm-ch-w-20 { width: 20ch;}
.form-item.ch-w-25, .form-clm-ch-w-25 { width: 25ch;}
.form-item.ch-w-30, .form-clm-ch-w-30 { width: 30ch;}
.form-item.ch-w-35, .form-clm-ch-w-35 { width: 35ch;}
.form-item.ch-w-40, .form-clm-ch-w-40 { width: 40ch;}
.form-item.ch-w-45, .form-clm-ch-w-45 { width: 45ch;}
.form-item.ch-w-50, .form-clm-ch-w-50 { width: 50ch;}
.form-item.ch-w-55, .form-clm-ch-w-55 { width: 55ch;}
.form-item.ch-w-60, .form-clm-ch-w-60 { width: 60ch;}
.form-item.ch-w-65, .form-clm-ch-w-65 { width: 65ch;}
.form-item.ch-w-70, .form-clm-ch-w-70 { width: 70ch;}

.form-clm-1 { width: 8.33%; }
.form-clm-2 { width: 16.66%; }
.form-clm-3 { width: 25%; }
.form-clm-4 { width: 33.33%; }
.form-clm-5 { width: 41.66%; }
.form-clm-6 { width: 50%;}
.form-clm-7 { width: 58.33%; }
.form-clm-8 { width: 66.66%; }
.form-clm-9 { width: 75%; }
.form-clm-10 { width: 83.33%; }
.form-clm-11 { width: 91.66%; }
.form-clm-12 { width: 100%; }
.form-clm-auto { width: auto; flex: 1 1 auto; }

input[type='text'].t-input-w-icon-right { padding:0 2em 0 0;}

@media only screen 
	and (min-width:320px) 
	and (max-width:639px) {
    
  .form-row { flex-direction: column; }
  .form-group { flex-direction: column; width: 100%; }
  .form-clm-auto { width: 100%; }
  .form-item { width: 100%; }
  .form-item > * { width: 100%; max-width: inherit;}
  .form-item > .icon { width: 1.25em; }

  .form-item.ch-w-10, .form-clm-ch-w-10 { width: 100%;}
  .form-item.ch-w-15, .form-clm-ch-w-15 { width: 100%;}
  .form-item.ch-w-20, .form-clm-ch-w-20 { width: 100%;}
  .form-item.ch-w-25, .form-clm-ch-w-25 { width: 100%;}
  .form-item.ch-w-30, .form-clm-ch-w-30 { width: 100%;}
  .form-item.ch-w-35, .form-clm-ch-w-35 { width: 100%;}
  .form-item.ch-w-40, .form-clm-ch-w-40 { width: 100%;}
  .form-item.ch-w-45, .form-clm-ch-w-45 { width: 100%;}
  .form-item.ch-w-50, .form-clm-ch-w-50 { width: 100%;}
  .form-item.ch-w-55, .form-clm-ch-w-55 { width: 100%;}
  .form-item.ch-w-60, .form-clm-ch-w-60 { width: 100%;}
  .form-item.ch-w-65, .form-clm-ch-w-65 { width: 100%;}
  .form-item.ch-w-70, .form-clm-ch-w-70 { width: 100%;}

}

@media only screen
  and (min-width:320px)
  and (max-width:580px) {

  .form-item.ch-w-10, .form-clm-ch-w-10 { width: 100%;}
  .form-item.ch-w-15, .form-clm-ch-w-15 { width: 100%;}
  .form-item.ch-w-20, .form-clm-ch-w-20 { width: 100%;}
  .form-item.ch-w-25, .form-clm-ch-w-25 { width: 100%;}
  .form-item.ch-w-30, .form-clm-ch-w-30 { width: 100%;}
  .form-item.ch-w-35, .form-clm-ch-w-35 { width: 100%;}
  .form-item.ch-w-40, .form-clm-ch-w-40 { width: 100%;}
  .form-item.ch-w-45, .form-clm-ch-w-45 { width: 100%;}
  .form-item.ch-w-50, .form-clm-ch-w-50 { width: 100%;}
  .form-item.ch-w-55, .form-clm-ch-w-55 { width: 100%;}
  .form-item.ch-w-60, .form-clm-ch-w-60 { width: 100%;}
  .form-item.ch-w-65, .form-clm-ch-w-65 { width: 100%;}
  .form-item.ch-w-70, .form-clm-ch-w-70 { width: 100%;}

}
