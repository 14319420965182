/* master style for responsiveness */
@import url(assets/css/master_style.css) only screen and (min-device-width: 581px),(min-width: 581px);
@import url(assets/css/master_style_mobile.css) only screen and (max-device-width: 580px),(max-width: 768px);

/* You can add global styles to this file, and also import other style files */
html,
body {
  width:100%;
  background-color: #171717;
  margin: 0;
  padding: 0;
  color: #fff;
  color-scheme: dark;
  z-index: 0;
}
.leaflet-tile-container img {
  width: 256.8px !important;
  height: 256.8px !important;
  background: #000 !important;
}

.content {
  color: #fff;
  background: #171717;
}


* {
  margin: 0;
  padding: 0;
  border: 0 none;
}

h1,
h2,
h3 {
  font-family: "DaimlerCAC-Regular", serif !important;
}

ul {
  list-style: none;
}

// body {
//   width: 100%;
// }

body.amg-trackpaceweb {
  background-color: #000;
  font-family: DaimlerCS-Regular, sans-serif;
}

.wrapper {
  width: 100%;
}

.amg-container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  min-width: 288px;
}
.container {
  margin-left: 50px;
}
.race-share-container {
  max-width: 1352px;
  margin: 0 auto;
}

.modal-content {
  background-color: #1f1f1f !important;
}

// @media (min-width: 768px) {
//   .amg-container {
//     width: 100%;
//     padding: 0 15px;
//   }
//   .container{
//     margin-left: 50px;
//   }
// }

// @media (min-width: 1024px) {
//   .amg-container {
//     width: 100%;
//     padding: 0 15px;
//   }
//   .container{
//     margin-left: 50px;
//   }
// }

// @media (min-width: 1280px) {
//   .amg-container {
//     width: 1154px;
//     padding: 0 0;
//   }
//   .container{
//     margin-left: 50px;
//   }
// }

// @media (min-width: 1440px) {
//   .amg-container {
//     width: 1352px;
//   }
//   .container{
//     margin-left: 50px;
//   }
// }
.leaflet-container {
  background-color: black !important;
  background-image: linear-gradient(to right, #17171787, #171717) !important;
} /* You can add global styles to this file, and also import other style files */


p,
div,
label,
span,
ul,
li,
img {
  margin: 0;
  padding: 0;
  border: 0 none;
  font-family: "MBcorp-web-Regular", sans-serif !important;
}

h4,
h5,
h6 {
  font-family: "MBcorp-web-Bold", sans-serif !important;
}


// body {
//   width: 100vw;
//   height: 100vh;
//   position: fixed;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   z-index: 0;
// }


// .container{
//   margin-left: 50px;
// }

// @media (min-width: 768px) {
//   .amg-container {
//     width: 90%;
//     padding: 0 15px;
//   }
//   // .container{
//   //   margin-left: 50px;
//   // }
// }

// @media (min-width: 1024px) {
//   .amg-container {
//     width: 90%;
//     padding: 0 15px;
//   }
//   // .container{
//   //   margin-left: 50px;
//   // }
// }

// @media (min-width: 1280px) {
//   .amg-container {
//     width: 1154px;
//     padding: 0 0;
//   }
//   // .container{
//   //   margin-left: 50px;
//   // }
// }

// @media (min-width: 1440px) {
//   .amg-container {
//     width: 1300px;
//   }
//   // .container{
//   //   margin-left: 50px;
//   // }
// }

.leaflet-container a.leaflet-popup-close-button {
  display: none !important;
}
.leaflet-popup-tip-container {
  display: none !important;
}
.leaflet-popup-content-wrapper {
  width: 0px;
  height: 0px;
  background-color: transparent !important;
  border-radius: 50%;
}
.leaflet-popup.custompopup.leaflet-zoom-animated {
  left: -20px !important;
  bottom: -25px !important;
}
.popup-img-class {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  border-radius: 50px;
}

.scalePopup {
  background-color: #fff !important;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  transition-duration: 500ms;
  transform: scale(2);
}
a.leaflet-control-zoom-in,
a.leaflet-control-zoom-out {
  background: #2a2a2a !important;
  color: #fff;
  border: none;
  box-shadow: none;
  width: 3em;
  height: 3em;
  margin-bottom: 0.1em;
  border-radius: 50%;
  cursor: pointer;
}
.leaflet-control-zoom.leaflet-bar.leaflet-control {
  border: none;
  margin-right: 1em;
  margin-bottom: 4em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  @media screen and (max-width: 768px) {
    margin-bottom: 6em;
  }
}

.leaflet-touch .leaflet-control-attribution {
  display: none;
}

.leaflet-container img.leaflet-tile {
  mix-blend-mode: normal;
}

.leaflet-bar a {
  background-color: transparent !important;
}
.leaflet-bar a:hover,
.leaflet-bar a.leaflet-disabled {
  color: #bbb;
}


// .leaflet-touch,
// .leaflet-bar a:first-child,
// .leaflet-bar a:last-child {
//     border-bottom-left-radius: unset !important;
//     border-bottom-right-radius: unset !important;
// }
// a.leaflet-control-zoom-in {
//     border-top-left-radius: 0% !important;
//     border-top-right-radius: 0% !important;
// }

.custompopup .leaflet-popup-content-wrapper {
  background: transparent !important;
  color: #fff !important;
  border-radius: 0px !important;
  width: auto;
  height: 5em;
}
.custompopup .leaflet-popup-content-wrapper a {
  color: #c8c8c81a;
}
.custompopup .leaflet-popup-content-wrapper div > font {
  font-size: 2em;
  font-family: "MBCorpoSText-Regular-Web", sans-serif;
}
.custompopup .leaflet-popup-content-wrapper h1 {
  font-family: "MBCorpoSText-Bold-Web", sans-serif;
  font-size: 2em;
  font-weight: bold;
  line-height: 1.21;
  color: #fff;
  // margin: 12px 0 4px !important;
}
.custompopup .leaflet-popup-content-wrapper div{
  font-family: "MBCorpoSText-Light-Web", sans-serif !important;
  margin-bottom: 2px !important;
}
.custompopup .leaflet-popup-content-wrapper div > p {
  font-family: "MBCorpoSText-Regular-Web", sans-serif !important;
  font-size: 2em;
  color: #fff;
  margin: 0 0 12px 0 !important;
}

.leaflet-verticalcenter {
  position: absolute;
  z-index: 444;
  pointer-events: none;
  bottom: 14em;
  /* possible because the placeholder's parent is the map */
}
.reveal-hightlights #cluster-list {
  transform: scale(0);
  opacity: 0;
  transition: all 0.25s ease-in-out;
}


#et-main .leaflet-touch .leaflet-bar a {
  width: 2em;
  height: 2em;
  line-height: 2em;
  border-radius: 50%;
  font-size: 2em;
  box-shadow: 0 0.125em 0.5em #00000080, 0 0.125em 0.25em #00000040;
  background: #000000cc;
  backdrop-filter: blur(0.5em);
}

.bookmark-icon {
  // padding-left: 8em;
  background-image: url(assets/images/bookmark_item.svg);
  background-repeat: no-repeat;
  background-size: contain;
  height: 2em;
}

.track-selected .track-details-card {
  border-color: #b21e23 !important;
}

.track-tab .track-item .track-bg { background-color: #232121; position: absolute; left: 0; right: 0; top: 0; bottom: 0.25em; z-index: -1; }
.track-tab.track-selected .track-item .track-bg { background-color: #353636; bottom: 0; } 
.track-tab.track-selected:first-child .track-item .track-bg { bottom: 0.25em; } 

.prof-image { 
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border: 1px solid #aaa;
    display: block;
    background: url(assets/images/icon_user.png); background-position: 50% 50%; background-color: #aaa; background-repeat: no-repeat; background-size: cover;  
  }

  .fav-icn { 
    width: 1.5em;
    height: 1.5em;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background: url(assets/images/icon-favorite@3x.png); background-position: 50% 50%; background-repeat: no-repeat; background-size: cover;  
  }

  .download-icn { 
    width: 1.5em;
    height: 1.5em;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background: url(assets/images/download.svg); 
    background-position: 50% 50%; background-repeat: no-repeat; background-size: cover;  
  }

  .share-icn { 
    width: 1.5em;
    height: 1.5em;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background: url(assets/images/share.png); 
    background-position: 50% 50%; background-repeat: no-repeat; background-size: cover;  
  }

  .rec-icn { 
    width: 1.5em;
    height: 1.5em;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background: url(assets/images/icon-recording@3x.png); background-position: 50% 50%; background-repeat: no-repeat; background-size: cover;  
  }



@media only screen and (max-width:1280px){
  html{
    height:100%;
  }
  body{
    font-size: 1vw;
    height:100%;
  }
}

@media only screen and (max-width:1280px) and (min-height: 961px){
  body{
    font-size: 1.5vh;
    height:100%;
  }
}

@media only screen and (max-width:1536px) and (min-height: 1153px){
  body{
    font-size: 1.5vh;
    height:100%;
  }
}



@media only screen and (max-width:1024px){
  html{
    height:100%;
  }
  body{
    font-size: 0.75em;
    height:100%;
  }
}

@media only screen and (max-width:800px){
  html{
    height:100%;
  }
  body{
    font-size: 0.675em;
    height:100%;
  }
}

@media only screen and (max-width:580px){
  body{
    font-size: 0.675em;
    height:100%;
  }
  #et-main .leaflet-touch .leaflet-bar a {
    font-size: 1.5em;
  }

}

@media only screen and (max-width:375px){
  body{
    font-size: 0.675em;
    height:100%;
  }
}

@media only screen and (max-width:320px){
  body{
    font-size: 0.625em;
    height:100%;
  }
}



@media only screen and (min-width:1921px)
  and (max-width: 2560px)
{
  html{
    height:100%;
  }
  body{
    font-size: 1em;
    height:100%;
  }
}


@media only screen
  and (min-width: 2561px) 
	and (max-device-height: 2400px){
	body {  font-size: 1.325vh; }    
}

@media only screen
  and (min-width: 3000px) 
	and (min-device-aspect-ratio: 16/9){
	body {  font-size: 1.325vh; }    
}



